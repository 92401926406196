import {
  ActivityEventProcessType,
  ActivityEventType,
} from '@warebee/shared/import-converter';
import { QueryCreator, sql } from 'kysely';
import _ from 'lodash';
import { toLocaleDateTimeDBString } from '../../../../common/dateTimeHelper';
import { DatasetExtraFields } from '../../actuality.types';
import { DatasetDatabase } from './datasetQueryBuilder';

export type ActivityFeedQueryBuilderParams = {
  datasetId: string;
  from?: Date;
  to?: Date;
  agentId?: string;
  disabledProcessTypes: ActivityEventProcessType[];
  disabledAgentTypes: string[];
  informationalEventTypes: ActivityEventType[];
  feedExtraFields: DatasetExtraFields[];
};

export function getActivityFeedFilteredQueryBuilder(
  params: ActivityFeedQueryBuilderParams,
  db: QueryCreator<DatasetDatabase>,
) {
  const start = params.from
    ? toLocaleDateTimeDBString(params.from)
    : new Date();
  const end = params.to ? toLocaleDateTimeDBString(params.to) : new Date();

  const hasAgentTypeFilter = !_.isEmpty(params.disabledAgentTypes);
  const hasProcessTypeFilter = !_.isEmpty(params.disabledProcessTypes);
  const hasAgentFilter = !_.isNil(params.agentId);

  let at = db
    .selectFrom('___activity_feed_iceberg___')
    .where('eventEndTime', '>=', sql<Date>`cast( ${start} as timestamp)`)
    .where('eventEndTime', '<=', sql<Date>`cast( ${end} as timestamp)`)
    .where('datasetObjectId', '=', params.datasetId)
    .$if(hasAgentTypeFilter, db =>
      db.where('agentType', 'not in', params.disabledAgentTypes),
    )
    .$if(hasProcessTypeFilter, db =>
      db.where('eventProcessType', 'not in', params.disabledProcessTypes),
    )
    .$if(hasAgentFilter, db => db.where('agentId', '=', params.agentId))
    .selectAll()
    .select(({ fn }) => [
      sql<string>`${sql.ref('consignee')} ||'-'|| ${sql.ref('sku')} `.as(
        'skuKey',
      ),
      sql<string>`${sql.ref('jobId')} || ${sql.ref('agentId')} `.as('jobKey'),
      ..._.map(params.feedExtraFields, fd =>
        sql<number>`json_value(${sql.ref('raw_data')}, 'strict $."${sql.raw(fd.name)}"' returning ${sql.raw(fd.type?.[0])})`.as(
          `extra_${fd.name}`,
        ),
      ),
    ]);
  return at;
}

// export function getActivityFeedFilteredSelectQueryBuilder(
//   params: ActivityFeedQueryBuilderParams,
//   db: QueryCreator<DatasetDatabase>,
// ) {
//   return getActivityFeedFilteredQueryBuilder(params, db)
//     .selectAll()
//     .select(({ fn }) => [
//       sql<string>`concat($s{sql.ref('jobId')}, ${sql.ref('agentId')})`.as(
//         'jobKey',
//       ),
//     ]);
// }
