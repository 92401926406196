import { TFunction } from 'i18next';
import { WizardStepConfig, WizardStepStatus } from '../../common/types';
import * as Icon from '../../components/icons';

export type SimulationMenuWizardStepId =
  | 'getting-started'
  | 'import-datasets'
  | 'import-layout'
  | 'import-assignments'
  | 'import-items'
  | 'import-orders'
  | 'data-import'
  | 'data-issues'
  | 'data-stats'
  | 'policy-resources'
  // | 'policy-locations-sharing'
  | 'policy-stacking'
  | 'policy-picking'
  | 'policy-batching'
  | 'policy-storage'
  | 'policy-routing'
  | 'policy-allocation'
  // | 'policy-replenishment'
  // | 'policy-putaway'
  | 'analyse'
  | 'compliance'
  | 'optimise'
  | 'optimise-reassign'
  | 'optimise-running'
  | 'optimise-implement'
  | 'allocation-requirement';

export type SimulationWizardConfig =
  WizardStepConfig<SimulationMenuWizardStepId>[];

export type SimulationWizardStatus = Record<
  SimulationMenuWizardStepId,
  WizardStepStatus
>;

export const simulationWizardStepsCommonDefault: (
  t: TFunction<'simulation'>,
) => SimulationWizardConfig = t => [
  {
    id: 'getting-started',
    menuLevel: 1,
    stepCounter: '1',
    title: t(`Getting Started`, { ns: 'simulation' }),
    titleMinimized: t(`Start`, { ns: 'simulation' }),
    icon: Icon.ArrowRight,
    treeLine: true,
    treeLineStart: true,
    sidebar: { 'sidebar-simulation-getting-started': { isCollapsed: false } },
    menuVisibility: {
      include: [], // Empty means it's included in all modes not in 'exclude'
      exclude: [], // Empty means it's included in all modes not in 'include'
    },
  },
  {
    id: 'import-datasets',
    menuLevel: 1,
    stepCounter: '1.1',
    title: t(`Datasets`, { ns: 'simulation' }),
    titleMinimized: t(`Datasets`, { ns: 'simulation' }),
    icon: Icon.Simulation,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-dataset': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-setup': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-dataset-import': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menuVisibility: {
      include: ['pro'],
      exclude: ['guided'],
    },
  },
  {
    id: 'import-layout',
    menuLevel: 1,
    stepCounter: '1.1',
    menuGroup: 'dataset',
    title: t(`Layout`, { ns: 'simulation' }),
    titleMinimized: t(`Layout`, { ns: 'simulation' }),
    icon: Icon.SimulationVisualize,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-dataset-layout': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-info-common': { isCollapsed: false },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menuVisibility: {
      include: ['guided'],
      exclude: ['pro'],
    },
  },
  {
    id: 'import-assignments',
    menuLevel: 1,
    stepCounter: '1.2',
    menuGroup: 'dataset',
    title: t(`Assignment (Stock)`, { ns: 'simulation' }),
    titleMinimized: t(`Assignment`, { ns: 'simulation' }),
    icon: Icon.DataAssignment,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-dataset-assignment': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-issues': { isCollapsed: false },
      'sidebar-simulation-info-common': { isCollapsed: false },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menuVisibility: {
      include: ['guided'],
      exclude: ['pro'],
    },
  },

  {
    id: 'import-items',
    menuLevel: 1,
    stepCounter: '1.3',
    menuGroup: 'dataset',
    title: t(`Items`, { ns: 'simulation' }),
    titleMinimized: t(`Items`, { ns: 'simulation' }),
    icon: Icon.DataItems,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-dataset-items': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-issues': { isCollapsed: false },
      'sidebar-simulation-info-common': { isCollapsed: false },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menuVisibility: {
      include: ['guided'],
      exclude: ['pro'],
    },
  },
  {
    id: 'import-orders',
    menuLevel: 1,
    stepCounter: '1.4',
    menuGroup: 'dataset',
    title: t(`Orders`, { ns: 'simulation' }),
    titleMinimized: t(`Orders`, { ns: 'simulation' }),
    icon: Icon.DataOrders,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-dataset-orders': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-issues': { isCollapsed: false },
      'sidebar-simulation-info-common': { isCollapsed: false },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menuVisibility: {
      include: ['guided'],
      exclude: ['pro'],
    },
  },
  {
    id: 'data-issues',
    menuLevel: 1,
    stepCounter: '1.5',
    menuGroup: 'dataset',
    title: t(`Data Stats & Issues`, { ns: 'simulation' }),
    titleMinimized: t(`Data Stats`, { ns: 'simulation' }),
    icon: Icon.TriangleInfo,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-issues-wide': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-simulation-info-common': { isCollapsed: false },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menumenuVisibility: {
      include: [],
      exclude: [],
    },
  },
  {
    id: 'data-stats',
    menuLevel: 1,
    stepCounter: '1.6',
    title: t(`Heatmaps`, { ns: 'simulation' }),
    titleMinimized: t(`Heatmaps`, { ns: 'simulation' }),
    icon: Icon.HeatMap9,
    treeLine: true,
    sidebar: {
      'sidebar-dataset-stats': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-dataset-stats-legend': {
        isCollapsed: false,
        openByDefault: true,
        isHidden: false,
      },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
    menumenuVisibility: {
      include: [],
      exclude: [],
    },
  },
  //-------------- POLICIES -----------------
  {
    id: 'policy-resources',
    menuLevel: 1,
    stepCounter: '2.1',
    menuGroup: 'policy',
    title: t(`Agents`, { ns: 'simulation' }),
    titleMinimized: t(`Agents`, { ns: 'simulation' }),
    icon: Icon.PolicyPickingAgents,
    treeLine: true,
    sidebar: {
      'sidebar-policy-resource': {
        isCollapsed: false,
        openByDefault: true,
      },
    },
  },

  //-------------- Routing policy -----------------
  {
    id: 'policy-routing',
    menuLevel: 1,
    stepCounter: '2.2',
    menuGroup: 'policy',
    title: t(`Routing policy`, { ns: 'simulation' }),
    titleMinimized: t(`Routing`, { ns: 'simulation' }),
    icon: Icon.PolicyAssignmentRoute,
    treeLine: true,
    sidebar: {
      'sidebar-routing-policy': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-routing-policy-editor': { isCollapsed: true },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
  },
  {
    id: 'policy-stacking',
    menuLevel: 1,
    stepCounter: '2.2',
    menuGroup: 'policy',
    title: t(`Stacking Policy`, { ns: 'simulation' }),
    titleMinimized: t(`Stacking Policy`, { ns: 'simulation' }),
    icon: Icon.PolicyStacking,
    treeLine: true,
    sidebar: {
      'sidebar-stacking-policy': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-stacking-policy-editor': { isCollapsed: true },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
  },
  //-------------- ASSIGNMENT POLICY -----------------
  {
    id: 'policy-storage',
    menuLevel: 1,
    stepCounter: '2.3',
    menuGroup: 'policy',
    title: t(`Storage Policy`, { ns: 'simulation' }),
    titleMinimized: t(`Storage Policy`, { ns: 'simulation' }),
    icon: Icon.PolicyAssignment,
    treeLine: true,
    sidebar: {
      'sidebar-policy-storage': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-policy-storage-editor': { isHidden: false, isCollapsed: false },
      // 'sidebar-policy-storage-stats': { isCollapsed: true },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
  },
  // {
  //   id: 'policy-locations-sharing',
  //   menuLevel: 1,
  //   stepCounter: '—',
  //   menuGroup: 'policy',
  //   title: `Location Policy`,
  //   titleMinimized: `Location Policy`,
  //   icon: Icon.PolicyLocationSharing,
  //   treeLine: true,
  //   sidebar: {
  //     'sidebar-policy-location-sharing': {
  //       isCollapsed: false,
  //       openByDefault: true,
  //     },
  //     'sidebar-policy-location-sharing-editor': { isCollapsed: true },
  //     'sidebar-simulation-info-common': { isCollapsed: true },
  //     'sidebar-simulation-info-location': { isHidden: true },
  //   },
  //   isHidden: true,
  // },

  {
    id: 'policy-picking',
    menuLevel: 1,
    stepCounter: '2.4',
    menuGroup: 'policy',
    title: t(`Picking Policy`, { ns: 'simulation' }),
    titleMinimized: t(`Picking Policy`, { ns: 'simulation' }),
    icon: Icon.PolicyAssignmentAgent,
    treeLine: true,
    sidebar: {
      'sidebar-policy-picking': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-policy-picking-editor': { isHidden: true, isCollapsed: false },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
  },
  // {
  //   id: 'policy-replenishment',
  //   menuLevel: 1,
  //   stepCounter: '6',
  //   menuGroup: 'dataset',
  //   title: 'Replenishment Policy',
  //   titleMinimized: 'Replenish Policy',
  //   icon: Icon.PolicyComplianceFire,
  //   treeLine: true,
  //   sidebar: {
  //     // 'sidebar-simulation-setup': { isCollapsed: true },
  //     'sidebar-policy-storage': {
  //       isCollapsed: false,
  //       openByDefault: true,
  //     },
  //     'sidebar-policy-storage-editor': { isCollapsed: true },
  //     'sidebar-simulation-info-common': { isCollapsed: true },
  //     'sidebar-simulation-info-location': { isHidden: true },
  //   },
  // },

  // -------------- ALLOCATION -----------------

  {
    id: 'policy-allocation',
    menuLevel: 1,
    stepCounter: '2.5',
    menuGroup: 'policy',
    title: t(`Allocation Policy`, { ns: 'simulation' }),
    titleMinimized: t(`Allocate Policy`, { ns: 'simulation' }),
    icon: Icon.PolicyAllocationRequirement,
    treeLine: true,
    sidebar: {
      'sidebar-policy-allocation': {
        isCollapsed: false,
        isHidden: false,
      },
      'sidebar-policy-allocation-editor': {
        isHidden: true,
        isCollapsed: false,
      },

      // 'sidebar-analyze-allocate': {
      //   isCollapsed: false,
      //   openByDefault: true,
      // },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
  },

  //-------------- ANALYSE -----------------
  {
    id: 'analyse',
    menuLevel: 1,
    stepCounter: '3',
    menuGroup: 'analysis',
    title: t(`Analyse`, { ns: 'simulation' }),
    titleMinimized: t(`Analyse`, { ns: 'simulation' }),
    icon: Icon.SimulationAnalyze,
    treeLine: true,
    sidebar: {
      'sidebar-simulation-analyze': { isCollapsed: false, openByDefault: true },
      'sidebar-simulation-routes': { isHidden: true },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
      'sidebar-policy-allocation-editor': { isCollapsed: true, isHidden: true },
    },
  },
  // {
  //   id: 'analyse-allocate',
  //   menuLevel: 1,
  //   stepCounter: '3.1.1',
  //   menuGroup: 'analysis',
  //   title: t(`Allocate`, { ns: 'simulation' }),
  //   titleMinimized: t(`Allocate`, { ns: 'simulation' }),
  //   icon: Icon.AllocationReport,
  //   treeLine: true,
  //   sidebar: {
  //     'sidebar-analyze-allocate': {
  //       isCollapsed: false,
  //       openByDefault: true,
  //     },
  //     'sidebar-simulation-info-common': { isCollapsed: true },
  //     'sidebar-simulation-info-location': { isHidden: true },
  //   },
  // },
  // {
  //   id: 'analyse-fte',
  //   menuLevel: 1,
  //   stepCounter: '3.1',
  //   menuGroup: 'analysis',
  //   title: t(`Workforce`, { ns: 'simulation' }),
  //   titleMinimized: t(`Workforce`, { ns: 'simulation' }),
  //   icon: Icon.SimulationWorkforce,
  //   treeLine: true,
  //   sidebar: {
  //     'sidebar-simulation-analyze-time-summary': {
  //       isCollapsed: false,
  //       openByDefault: true,
  //     },
  //   },
  // },

  //-------------- COMPLIANCE -----------------
  {
    id: 'compliance',
    menuLevel: 1,
    stepCounter: '4',
    menuGroup: 'compliance',
    title: t(`Compliance`, { ns: 'simulation' }),
    titleMinimized: t(`Compliance`, { ns: 'simulation' }),
    icon: Icon.PolicyComplianceVolume,
    treeLine: true,
    sidebar: {
      'sidebar-compliance': { isCollapsed: false, openByDefault: true },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
    },
  },
  //-------------- Optimization Swap Policy -----------------
  // {
  //   id: 'policy-swap',
  //   menuLevel: 1,
  //   stepCounter: '5.1',
  //   menuGroup: 'policy',
  //   title: t(`Optimise policy`, { ns: 'simulation' }),
  //   titleMinimized: t(`Optimise policy`, { ns: 'simulation' }),
  //   icon: Icon.PolicySwap,
  //   treeLine: true,
  //   sidebar: {
  //     'sidebar-policy-swap': {
  //       isCollapsed: false,
  //       openByDefault: true,
  //     },
  //     'sidebar-policy-swap-editor': { isCollapsed: true },
  //     'sidebar-simulation-info-common': { isCollapsed: true },
  //     'sidebar-simulation-info-location': { isHidden: true },
  //   },
  //   isHidden: false,
  // },
  //-------------- OPTIMISATION  -----------------
  {
    id: 'optimise',
    stepCounter: '5',
    menuLevel: 1,
    menuGroup: 'optimisation',
    title: t(`Optimise`, { ns: 'simulation' }),
    titleMinimized: t(`Optimise`, { ns: 'simulation' }),
    icon: Icon.SimulationOptimize,
    treeLine: true,
    sidebar: {
      'sidebar-policy-swap': {
        isCollapsed: false,
        openByDefault: true,
      },
      'sidebar-policy-swap-editor': { isCollapsed: true, isHidden: false },
      'sidebar-optimisation-results': { isCollapsed: false },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
      'sidebar-simulation-routes': { isHidden: true },
    },
  },
];

export const simulationWizardStepsOptimizeDefault: (
  t: TFunction<'simulation'>,
) => SimulationWizardConfig = t => [
  ...simulationWizardStepsCommonDefault(t),

  // {
  //   treeLine: true,
  //   id: 'optimise-results-summary',
  //   stepCounter: '4',
  //   menuLevel: 1,
  //   menuGroup: 'optimisation',
  //   title: 'Optimisation Summary',
  //   titleMinimized: 'Summary',
  //   //disabled: true,
  //   icon: Icon.CircleArrowRight,
  //   sidebar: {
  //     'sidebar-optimisation-results': { isCollapsed: false },
  //     'sidebar-simulation-info-common': { isCollapsed: true },
  //     'sidebar-simulation-info-location': { isHidden: true },
  //   },
  // },
  // {
  //   treeLine: true,
  //   id: 'optimise-result-detail',
  //   menuLevel: 1,
  //   menuGroup: 'optimisation',
  //   title: 'Optimised 12.3%',
  //   titleMinimized: '12.3%',
  //   //disabled: true,
  //   icon: Icon.CircleArrowRight,
  //   sidebar: {
  //     'sidebar-optimisation-results': {},
  //     'sidebar-optimisation-results-items': {},
  //   },
  // },
  // {
  //   treeLine: true,
  //   id: 'optimise-result-detail2',
  //   menuLevel: 1,
  //   menuGroup: 'optimisation',
  //   title: 'Optimised 33.3%',
  //   titleMinimized: '33.3%',
  //   //disabled: true,
  //   icon: Icon.CircleArrowRight,
  //   sidebar: {
  //     'sidebar-optimisation-results': {},
  //     'sidebar-optimisation-results-items': {},
  //   },
  // },
  // {
  //   treeLine: true,
  //   id: 'optimise-purchase',
  //   menuLevel: 3,
  //   title: 'Purchase',
  //   titleMinimized: 'Purchase',
  //   //disabled: true,
  //   icon: Icon.CircleArrowRight,
  //   sidebar: {
  //     'sidebar-optimisation-results': {},
  //     'sidebar-optimisation-results-items': {},
  //   },
  // },
  {
    id: 'optimise-reassign',
    stepCounter: '5.1',
    menuLevel: 1,
    title: t(`Re-assign Plan`, { ns: 'simulation' }),
    titleMinimized: t(`Re-assign`, { ns: 'simulation' }),
    //disabled: true,
    icon: Icon.PickGesture,
    treeLine: true,
    sidebar: {
      'sidebar-optimisation-results-items': { isCollapsed: false },
      'sidebar-simulation-info-common': { isCollapsed: true },
      'sidebar-simulation-info-location': { isHidden: true },
      'sidebar-simulation-routes': { isHidden: true },
    },
  },
  {
    id: 'optimise-implement',
    stepCounter: '5.2',
    menuLevel: 1,
    title: t(`Implement`, { ns: 'simulation' }),
    titleMinimized: t(`Implement`, { ns: 'simulation' }),
    icon: Icon.GridTop,
    treeLine: true,
    treeLineEnd: true,
    sidebar: {
      'sidebar-optimisation-results-implement': { isCollapsed: false },
    },
  },
];

export const stepsWithAnalyse: SimulationMenuWizardStepId[] = [
  'analyse',
  'optimise',
];
