import { BatchJobStatus } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../../common/constants';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import ErrorIndicator from '../../../components/ErrorIndicator';
import LoadingIndicator from '../../../components/LoadingIndicator';
import * as Icon from '../../../components/icons';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import useLoadAssignmentOccupancyMeta from '../../hooks/useLoadAssignmentOccupancyMeta';
import {
  assignmentOccupancyMeta,
  assignmentOccupancyMetaLoadStatus,
} from '../../store/occupancy/assignmentOccupancy.state';
import {
  simulationCurrentId,
  simulationEffectiveAssignmentId,
} from '../../store/simulation.state';
import OccupancyByCategory from './occupancy/OccupancyByCategory';
import OccupancyByGroup from './occupancy/OccupancyByGroup';
import OccupancySummaryPanel from './occupancy/OccupancySummaryPanel';

const ComplianceVolumePanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrentId);
  const assignmentId = useRecoilValue(simulationEffectiveAssignmentId);
  const formatter = useFormatter();

  // const [showLocationsTable, setShowLocationsTable] = useRecoilState(
  //   assignmentOccupancyShowLocationsTable,
  // );
  const complianceMeta = useRecoilValue(assignmentOccupancyMeta);
  const complianceMetaLoadStatus = useRecoilValue(
    assignmentOccupancyMetaLoadStatus,
  );
  const [loadMeta, cancelLoadMeta] = useLoadAssignmentOccupancyMeta();

  useEffect(() => {
    if (complianceMetaLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;
    // compliance not triggered
    if (_.isNil(complianceMeta?.status)) {
      loadMeta({
        simulationId,
        assignmentId,
      });
    }
    if (
      complianceMeta?.status === BatchJobStatus.CREATED ||
      complianceMeta?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadMeta({
          simulationId,
          assignmentId,
        });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
      cancelLoadMeta();
    };
  }, [complianceMeta]);

  const hasError = complianceMetaLoadStatus === AsyncLoadStatus.Error;
  const isLoading = complianceMeta?.status !== BatchJobStatus.READY;

  const summary = complianceMeta?.summary;

  return (
    <>
      <ScreenTitle
        subtitle={t`Analyse — Compliance`}
        title={t`Volume & Occupancy`}
        // isSticky // Disabled so it's won't interfere with the Stat sticky-ness.
        helpNavTo={'simulation/compliance/simulation-compliance-volume'}
        icon={Icon.PolicyComplianceVolume}
      />

      {hasError && (
        <ErrorIndicator message={t`Cannot show volume compliance issues`} />
      )}
      {isLoading && !hasError && (
        <LoadingIndicator message={t`Loading Volume data`} selfCenter />
      )}
      {!isLoading && !hasError && (
        <>
          <OccupancySummaryPanel summary={summary} />
          {summary.totalOccupancy > 0 && <OccupancyByGroup />}
          <Suspense
            fallback={<LoadingIndicator selfCenter message={t`Loading...`} />}
          >
            <OccupancyByCategory />
          </Suspense>
        </>
      )}
    </>
  );
};

export default ComplianceVolumePanel;
