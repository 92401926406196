import {
  LayoutFeatureDetails,
  LayoutFeatureType,
  LocationPortal,
  PlaneAccessSpec,
  PlaneConnectivityGraph,
} from '@warebee/shared/engine-model';
import { MappingSchema } from '../mapping';

export class LayoutLocationAthena {
  locationId: string;

  locationOrder: string; // may exceed js int
  locationStatus: boolean;
  locationLevel: number;

  locationLength: number;
  locationWidth: number;
  locationHeight: number;
  locationWeight: number;

  locationBayId: string;
  locationBayTitle: string;

  locationBayPosition: number;
  locationDepthPosition: number;

  locationUsageType: string;
  locmhtype?: string;
  locationRackingType: string;
  warehouseArea: string;
  locationSide?: string;
  congestionZone?: string;
  locationBayProjection: number;
  locationHeightFromFloor: number;
  locationDepthFromFront: number;
  locationIndexFromFront: number;

  bayType?: string;

  aisleId: string;
  aisleTitle: string;

  planeId: string;
  planeTitle: string;

  shape?: GeoJSON.Polygon;
  portals?: LocationPortal[];
}

export const LAYOUT_LOCATION_MAPPING_SCHEMA: MappingSchema<LayoutLocationAthena> =
  {
    fields: [
      { name: 'locationId', type: 'string' },
      { name: 'locationOrder', type: 'string' },
      { name: 'locationStatus', type: 'boolean' },
      { name: 'locationLevel', type: 'integer' },

      { name: 'locationLength', type: 'number' },
      { name: 'locationWidth', type: 'number' },
      { name: 'locationHeight', type: 'number' },
      { name: 'locationWeight', type: 'number' },

      { name: 'locationBayId', type: 'string' },
      { name: 'locationBayTitle', type: 'string' },

      { name: 'locationBayPosition', type: 'integer' },
      { name: 'locationDepthPosition', type: 'integer' },

      { name: 'locationUsageType', type: 'string' },
      { name: 'locmhtype', type: 'string', optional: true },
      { name: 'locationRackingType', type: 'string' },
      { name: 'warehouseArea', type: 'string' },
      { name: 'locationSide', type: 'string', optional: true },
      { name: 'congestionZone', type: 'string', optional: true },
      { name: 'locationBayProjection', type: 'number' },
      { name: 'locationHeightFromFloor', type: 'number' },
      { name: 'locationDepthFromFront', type: 'number' },
      { name: 'locationIndexFromFront', type: 'integer' },

      { name: 'bayType', type: 'string', optional: true },

      { name: 'aisleId', type: 'string' },
      { name: 'aisleTitle', type: 'string' },

      { name: 'planeId', type: 'string' },
      { name: 'planeTitle', type: 'string' },

      { name: 'shape', type: 'string', optional: true },
      { name: 'portals', type: 'string', optional: true },
    ],
  };

export class LayoutFeatureAthena {
  id: string;
  type: LayoutFeatureType;

  planeId: string;
  planeTitle: string;

  title?: string;
  shape?: GeoJSON.Polygon;
  innerShape?: GeoJSON.Polygon;

  navigable: boolean;
  details?: LayoutFeatureDetails;
}

export const LAYOUT_FEATURE_MAPPING_SCHEMA: MappingSchema<LayoutFeatureAthena> =
  {
    fields: [
      { name: 'id', type: 'string' },
      { name: 'type', type: 'string' },
      { name: 'planeId', type: 'string' },
      { name: 'planeTitle', type: 'string' },
      { name: 'title', type: 'string', optional: true },
      { name: 'shape', type: 'string', optional: true },
      { name: 'innerShape', type: 'string', optional: true },
      { name: 'navigable', type: 'boolean' },
      { name: 'details', type: 'string', optional: true },
    ],
  };

export class LayoutPlaneAthena {
  id: string;
  title?: string;
  start?: PlaneAccessSpec;
  end?: PlaneAccessSpec;
  connectivityGraph?: PlaneConnectivityGraph;
  boundingBox?: GeoJSON.BBox;
  outlineShape?: GeoJSON.Geometry;
  levels?: number[];
}

export const LAYOUT_PLANE_MAPPING_SCHEMA: MappingSchema<LayoutPlaneAthena> = {
  fields: [
    { name: 'id', type: 'string' },
    { name: 'title', type: 'string', optional: true },
    { name: 'start', type: 'string', optional: true },
    { name: 'end', type: 'string', optional: true },
    { name: 'connectivityGraph', type: 'string', optional: true },
    { name: 'boundingBox', type: 'string', optional: true },
    { name: 'outlineShape', type: 'string', optional: true },
    { name: 'levels', type: 'string', optional: true },
  ],
};

export interface LayoutDatabase {
  layoutLocation: LayoutLocationAthena & { datasetObjectId: string };
  layoutPlane: LayoutPlaneAthena & { datasetObjectId: string };
  layoutFeature: LayoutFeatureAthena & { datasetObjectId: string };
}
