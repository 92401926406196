import { default as classNames, default as classnames } from 'classnames';
import _ from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../../AnalyticTracker';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import CopyToClipboardButton from '../../components/actions/CopyToClipboardButton';
import ItemTag from '../../simulation/tags/ItemTag';
import StatDiffIcon from './StatDiffIcon';
import { UnitOfMeasure } from './UnitOfMeasure';

// enum LegendStyle {
//   square = 'w-4 h-4 rounded-full',
//   circle = 'w-4 h-4 border-4',
//   line = 'w-2 h-4 rounded-sm',
//   torus = 'w-4 h-4 rounded-full border-4',
// }
// export const legendStyle = {
//   square: 'w-4 h-4 rounded-full',
//   circle: 'w-4 h-4 border-4',
//   line: 'w-2 h-4 rounded-sm',
//   torus: 'w-4 h-4 rounded-full border-4',
// };

export const textSizeStyle = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-xl',
};
// export const legendType = {
//   square: 'w-4 h-4 rounded-full',
//   circle: 'w-4 h-4 border-4',
//   line: 'w-2 h-4 rounded-sm',
//   torus: 'w-4 h-4 rounded-full border-4',
// };

export type StatListItemProps = {
  children?: React.ReactNode;
  value?: string | number;
  valueTotal?: string | number;
  valuePercent?: string | number;
  valueRaw?: number | string;
  title?: string | React.ReactNode;
  isComparable?: boolean;
  multiline?: boolean;
  valueAfter?: FormattedValueWithUnit;
  valueBefore?: FormattedValueWithUnit;
  label?: string;
  isActionable?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isPrimaryResult?: boolean;
  cardView?: boolean;
  summaryView?: boolean;
  diffNegative?: boolean;
  diffPositive?: boolean;
  diffNone?: boolean;
  legendColor?: string;
  // legendStyle?: typeof legendStyle;
  legendType?: string;
  textSizeStyle?: typeof textSizeStyle;
  unitOfMeasure?: string | React.ReactNode;
  unitOfMeasureTotal?: string | React.ReactNode;
  separator?: string | React.ReactNode;
  titleClass?: string;
  titleTrace?: string;
  valueClass?: string;
  statClass?: string;
  legendClass?: string;
  className?: string;
  valueStyle?: CSSProperties;
  titleSelectable?: boolean;
  titleUppercase?: boolean;
  isUnitPrefix?: boolean;
  styleInput?: boolean;
  truncate?: boolean;
  inStatView?: boolean;
  canCopy?: boolean;
  labelValueType?: 'itemId' | 'locationId' | 'orderId';
  labelValueFilters?: Record<string, string>;
};

export const StatListItem = ({
  children,
  value,
  valueRaw,
  valueTotal,
  valuePercent,
  valueAfter,
  valueBefore,
  isComparable,
  multiline,
  title,
  label,
  isActionable,
  onClick,
  isSelected,
  isPrimaryResult,
  cardView,
  summaryView,
  inStatView,
  diffNegative,
  diffPositive,
  diffNone,
  legendColor,
  legendType,
  unitOfMeasure,
  unitOfMeasureTotal,
  separator,
  valueStyle,
  titleSelectable,
  titleUppercase,
  titleTrace,
  isUnitPrefix,
  textSizeStyle,
  valueClass,
  titleClass,
  statClass,
  className,
  legendClass,
  labelValueType,
  labelValueFilters,
  truncate,
  styleInput,
  canCopy,
}: StatListItemProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  const legendTypeClasses = classNames({
    'w-4 h-4': legendType === 'square',
    'w-4 h-4 rounded-full': legendType === 'circle',
    'w-1 xl:w-2 h-4 xl:h-7 rounded-sm': legendType === 'line',
    'w-4 h-4 rounded-full border-2': legendType === 'torus',
  });

  const emptyValue = value === '0' || value === 0;

  return (
    <div
      id={id}
      data-component={`StatListItem`}
      data-label={`StatList-item-${title || ''}`}
      aria-label={`StatList-item-${title || ''}${label || ''}`}
      className={classnames(
        'relative',
        'group',
        'flex flex-1 items-start xl:items-center',
        'z-auto',

        {
          hidden: cardView,
        },
        {
          'z-0': labelValueType === 'itemId',
        },
        statClass,
      )}
      onClick={() => {
        onClick && onClick();
        try {
          analyticsAgent?.track(`Stat List: [${titleTrace || title || label}]`);
        } catch (ex) {
          console.debug('analyticsAgent Error:', ex);
        }
      }}
    >
      <div
        data-component="StatListItemContainer"
        className={classNames(
          'w-full',
          multiline
            ? 'flex-col items-start'
            : 'flex flex-1 flex-col items-start md:flex-row md:flex-wrap md:items-center',
          {
            'border-menu/30 border-b px-1': !styleInput && !inStatView,
          },
          {
            'mt-0.5 py-1': !styleInput,
          },
          isActionable
            ? isPrimaryResult
              ? 'hover:bg-app-panel-dark/70 hover:text-menu-stat cursor-pointer'
              : // Stat Line with Active button
                'hover:bg-menu-active/80 hover:text-menu-active-text cursor-pointer rounded hover:border-opacity-0' //bg-menu-active text-menu-active-texts
            : isPrimaryResult
              ? 'text-menu-stat'
              : '',
          isSelected
            ? isPrimaryResult
              ? 'isSelected bg-menu-active text-menu-active-text'
              : inStatView
                ? ''
                : 'bg-menu-active text-menu-active-text' //bg-menu-active text-menu-active-text
            : isPrimaryResult
              ? 'text-menu-stat'
              : inStatView
                ? ''
                : null, // 'text-menu-stat/90',
          { truncate: truncate },
          { 'cursor-default': summaryView },
          className,
        )}
      >
        {legendColor && (
          <div
            data-component="LegendStatItem"
            className={classNames(
              'ltr:ml-0 ltr:mr-2 rtl:ml-2 rtl:mr-0',
              legendTypeClasses,
              legendClass,
            )}
            style={
              legendType === 'torus'
                ? { borderColor: legendColor }
                : { backgroundColor: legendColor }
            }
          />
        )}

        {labelValueType === 'itemId' && typeof title === 'string' && (
          <ItemTag title={title} filters={labelValueFilters} />
        )}
        <label
          data-component="StatListItemTitle"
          className={classNames(
            'w-full',
            multiline
              ? '!text-xxs mb-1 flex-1 items-start uppercase opacity-60'
              : 'my-0 flex flex-1 items-center justify-start whitespace-nowrap lg:my-0',
            summaryView
              ? 'text-xxs opacity-75 lg:text-xs xl:text-sm ltr:pl-0.5 xl:ltr:pl-2 rtl:pr-0.5 xl:rtl:pr-2'
              : titleClass
                ? titleClass
                : 'text-xxs ltr:xl:pl-0.5 rtl:xl:pr-0.5',
            isActionable
              ? isSelected
                ? 'opacity-60'
                : isPrimaryResult
                  ? 'isPrimaryResult'
                  : 'opacity-60'
              : isPrimaryResult
                ? 'opacity-60'
                : 'opacity-60 ltr:mr-2 rtl:ml-2',
            { 'select-text': titleSelectable },
            'uppercase',
            // { uppercase: titleUppercase },
          )}
        >
          {_.isNil(labelValueType) && title}
          {label && (
            <div
              data-component="StatLabel"
              className={classNames(
                'px-2 py-0.5 pb-0',
                'ltr:ml-2 rtl:mr-2',
                'text-xs lg:text-xs',
                'rounded',
                'border-menu-50/30 border',
                'text-menu-text/60',
                'items-center',
              )}
            >
              {label}
            </div>
          )}
        </label>

        {!_.isNil(value) && (
          <div
            data-component="StatListValue"
            className={classNames(
              'flex items-baseline',
              { 'px-1 xl:px-2 xl:py-0.5': summaryView },
              { truncate: truncate },
              {
                'bg-app-panel-dark/70 max-w-1/2 min-w-20 rounded px-1 py-1':
                  styleInput,
              },
            )}
          >
            {isUnitPrefix &&
              (_.isString(value) ? (
                value === '0' ? null : (
                  <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
                )
              ) : // ifNumbers...
              value === 0 ? null : (
                <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
              ))}
            <div
              data-component="StatValueContainer"
              className={classNames(
                'flex-1',
                multiline ? 'text-start' : 'text-start xl:text-end',
                'text-xxs font-bold md:text-xs lg:text-sm',
                emptyValue || !isUnitPrefix || !unitOfMeasure
                  ? ''
                  : 'px-0.5 xl:px-1',
                isActionable
                  ? isSelected
                  : isPrimaryResult
                    ? 'text-xl font-black md:text-2xl'
                    : valueClass
                      ? valueClass
                      : 'text-base md:text-lg',
                valueStyle ? 'text-end- text-xxs rounded p-0.5 px-1' : '',
                'truncate',
                { truncate: truncate },
              )}
              style={valueStyle}
            >
              <span
                title={valueRaw ? `RAW: ${valueRaw}` : null}
                data-value={value}
                data-component={`statValue${
                  _.isString(value) ? 'String' : 'Number'
                }`}
                className={classNames(
                  emptyValue || !isUnitPrefix || !unitOfMeasure
                    ? ''
                    : 'ltr:xl:pr-1 rtl:xl:pl-1',

                  { 'opacity-50': emptyValue },
                )}
              >
                {
                  _.isString(value)
                    ? value === '0'
                      ? '—'
                      : value // ifString
                    : typeof value === 'number'
                      ? value === 0
                        ? '——'
                        : value < 0.01
                          ? '< 0.01'
                          : value < 0.1
                            ? '< 0.1'
                            : value < 1
                              ? value.toFixed(2)
                              : value < 10
                                ? value.toFixed(1)
                                : value.toFixed(0)
                      : _.isObject(value) || _.isArray(value)
                        ? JSON.stringify(value)
                        : value // handle other cases
                }
                {unitOfMeasure && !isUnitPrefix && (
                  <UnitOfMeasure
                    unitOfMeasure={unitOfMeasure}
                    isSelected={isSelected}
                  />
                )}
              </span>

              {valueTotal && (
                <div
                  data-component="StatListValueTotal"
                  className={classNames('mx-0 ltr:xl:ml-1 rtl:xl:mr-1')}
                >
                  {value !== '0' && value !== 0 && !_.isNaN(value) && (
                    <span
                      className={classNames(
                        'opacity-50',
                        'mr-0.5 xl:mr-1',
                        'text-xs xl:text-sm',
                      )}
                    >{t`of`}</span>
                  )}

                  <span
                    className={classNames(
                      'flex-1 text-start',
                      'text-xxs font-bold md:text-xs lg:text-sm xl:text-end',
                      emptyValue || !isUnitPrefix || !unitOfMeasureTotal
                        ? ''
                        : 'px-0.5 xl:px-1',
                      isActionable
                        ? isSelected
                        : isPrimaryResult
                          ? 'text-xl font-black md:text-2xl'
                          : valueClass
                            ? valueClass
                            : 'text-base md:text-lg',
                      valueStyle ? 'text-xxs rounded p-0.5 px-1 text-end' : '',
                    )}
                  >
                    {valueTotal}
                  </span>
                  <UnitOfMeasure unitOfMeasure={unitOfMeasureTotal} />
                </div>
              )}
            </div>

            {!isComparable && valueAfter && (
              <>
                {isUnitPrefix && unitOfMeasure && (
                  <UnitOfMeasure
                    unitOfMeasure={valueAfter.unit}
                    isSelected={isSelected}
                  />
                )}

                <div
                  className={classNames(
                    'flex-1 text-end',
                    isSelected
                      ? ''
                      : isPrimaryResult
                        ? 'isPrimaryResult font-bold opacity-90'
                        : '',
                  )}
                >
                  {valueAfter.value}
                </div>

                <div className="flex flex-col items-start">
                  <div className="text-menu-600 text-xs">{t`After`}</div>
                  <div className="flex-1">{valueAfter.value}</div>
                </div>
                {!isUnitPrefix &&
                  (_.isString(value) ? (
                    value === '0' ? null : (
                      <UnitOfMeasure unitOfMeasure={valueAfter.unit} />
                    )
                  ) : // ifNumbers...
                  value === 0 ? null : (
                    <UnitOfMeasure unitOfMeasure={valueAfter.unit} />
                  ))}
              </>
            )}
          </div>
        )}

        {isComparable && (
          <div
            data-component="valuesBeforeAfter"
            className={classNames(
              multiline
                ? 'w-full'
                : 'w-full xl:w-2/3 ltr:justify-start ltr:md:justify-end rtl:justify-end rtl:md:justify-start',
              'flex flex-1',
              'items-start md:items-center',
              // 'px-0.5',
              // 'xl:px-2',
              // isActionable
              //   ? isSelected
              //     ? 'isSelected'
              //     : ''
              //   : isPrimaryResult
              //   ? 'isPrimaryResult'
              //   : '',
            )}
          >
            {valueBefore && (
              <div className={classNames('flex items-baseline')}>
                {isUnitPrefix && (
                  <UnitOfMeasure
                    unitOfMeasure={valueBefore.unit}
                    isSelected={isSelected}
                  />
                )}
                <div
                  className={classNames(
                    'flex-1 text-end',
                    isSelected
                      ? ''
                      : isPrimaryResult
                        ? 'isPrimaryResult text-opacity-80'
                        : '',
                  )}
                >
                  {valueBefore.value}
                </div>
                {!isUnitPrefix && (
                  <UnitOfMeasure
                    unitOfMeasure={valueBefore.unit}
                    isSelected={isSelected}
                  />
                )}
              </div>
            )}

            {separator ? (
              <span
                className={classNames('ltr:mr-0.5 rtl:ml-0.5', 'opacity-75')}
              >
                {separator}
              </span>
            ) : (
              <StatDiffIcon
                className={classNames(
                  'h-3 w-3 xl:h-3 xl:w-3',
                  'ltr:mr-0.5 rtl:ml-0.5',
                  'fill-current',
                  'opacity-75',
                )}
                before={valueBefore}
                after={valueAfter}
              />
            )}

            {valueAfter && (
              <div className={`flex items-baseline`}>
                {isUnitPrefix && (
                  <UnitOfMeasure
                    unitOfMeasure={unitOfMeasure}
                    isSelected={isSelected}
                  />
                )}
                <div
                  className={classNames(
                    'flex-1 text-end',
                    isSelected
                      ? ''
                      : isPrimaryResult
                        ? 'isPrimaryResult ext-opacity-80'
                        : '',
                  )}
                >
                  {valueAfter.value}
                </div>
                {!isUnitPrefix && (
                  <UnitOfMeasure
                    unitOfMeasure={unitOfMeasure}
                    isSelected={isSelected}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {valuePercent && (
          // valuePercent > 0 &&
          <div
            data-component="StatListValuePercentNumber"
            className={classNames(
              'ltr:ml-1 rtl:mr-1',
              'text-end text-xs',
              'p-0.5 px-2',
              'rounded',
              'border-menu-400 border',
            )}
          >
            <span className={classNames('')}>{valuePercent}</span>
            <UnitOfMeasure unitOfMeasure={`%`} />
          </div>
        )}
      </div>
      {canCopy && (
        <CopyToClipboardButton
          hasIconSmall
          className={classNames(
            'opacity-0 group-hover:opacity-100',
            'absolute',
            'top-4 -translate-y-1/2',
            'right-[-1.35rem]',
            'transition-opacity duration-300 ease-in-out',
          )}
          value={`${title ? title + ':\n' : ''}${value}`}
        />
      )}
      <div aria-label="children">{children}</div>
    </div>
  );
};
