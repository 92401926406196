import { AnalyzeResultStatus } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { atom, selector } from 'recoil';

import { recoilPersist } from 'recoil-persist';
import { persistAtom } from '../../common/recoil/persistAtom';
import { WizardStepConfig } from '../../common/types';
import { SidebarState, SidebarType } from '../../store/sidebar.state';
import { userHasOptimise } from '../../store/warehouse.state';
import { optimisationStatus } from './optimisation.state';
import { getWizardSteps } from './simulation.helper';
import {
  simulationAnalyzeStatus,
  simulationCurrent,
  simulationCurrentId,
} from './simulation.state';
import {
  AllocateTabId,
  AnalyseTabId,
  ComplianceTabId,
  OptimisationPolicyTabId,
  PoliciesTabId,
  RoutingTabId,
} from './simulation.types';
import {
  SimulationMenuWizardStepId,
  SimulationWizardConfig,
  stepsWithAnalyse,
} from './simulation.wizard';

const getKey = (postfix: string) => `warebee-simulation-wizard-${postfix}`;

export const simulationWizardStepsNoTitles = selector<SimulationWizardConfig>({
  key: getKey('all-steps'),
  get: ({ get }) => {
    const sim = get(simulationCurrent);
    return getWizardSteps(
      sim,
      get(simulationAnalyzeStatus) === AnalyzeResultStatus.COMPLETED,
      get(optimisationStatus),
      get(userHasOptimise),
      _.identity as TFunction,
    );
  },
});

// Selected step atom [For internal usage]
const simulationWizardStepBySimId = persistAtom<
  Record<string, SimulationMenuWizardStepId>
>({
  key: getKey('selected-step-by-sim'),
  default: {},
  effects: [
    recoilPersist({
      key: 'warebee-sim-wizard',
      storage: localStorage,
    }).persistAtom,
  ],
});

// Selected step selector
export const simulationWizardSelectedStepId =
  selector<SimulationMenuWizardStepId>({
    key: getKey('selected-step-id'),
    get: ({ get }) => {
      const simId = get(simulationCurrentId);
      if (_.isNil(simId)) return null;
      if (simId === 'new') return 'getting-started';
      const current =
        get(simulationWizardStepBySimId)?.[simId] ??
        (get(simulationAnalyzeStatus) === AnalyzeResultStatus.COMPLETED
          ? 'analyse'
          : 'import-layout');
      const allSteps = get(simulationWizardStepsNoTitles);
      const step = _.find(allSteps, s => s.id === current);
      const isAvailable = step && !step.disabled;
      return isAvailable ? current : 'getting-started';
    },
    set: ({ get, set }, value) => {
      const simId = get(simulationCurrentId);
      const current = get(simulationWizardStepBySimId);
      const stepBySimId = {
        ...current,
        [simId]: value,
      };
      set(simulationWizardStepBySimId, stepBySimId);
    },
  });

export const simulationWizardSelectedStep = selector<
  WizardStepConfig<SimulationMenuWizardStepId>
>({
  key: getKey('selected-step'),
  get: ({ get }) => {
    const currentId = get(simulationWizardSelectedStepId);
    return _.find(get(simulationWizardStepsNoTitles), s => s.id === currentId);
  },
});

export const simulationEnabledSidebars = selector<
  Partial<Record<SidebarType, Partial<SidebarState>>>
>({
  key: getKey('enabled-sidebars'),
  get: ({ get }) => {
    const step = get(simulationWizardSelectedStep);
    return step?.sidebar;
  },
});

export const showAnalyseData = selector<boolean>({
  key: getKey('show-analyse-data'),
  get: ({ get }) =>
    stepsWithAnalyse.includes(get(simulationWizardSelectedStepId)),
});

export const simulationComplianceTabKey = atom<ComplianceTabId>({
  key: getKey('compliance-tab-key'),
  default: 'tab-compliance-policy',
});

export const simulationRoutingTabKey = atom<RoutingTabId>({
  key: getKey('routing-tab-key'),
  default: 'tab-routing-policy',
});

export const simulationAllocateTabKey = atom<AllocateTabId>({
  key: getKey('allocate-tab-key'),
  default: 'tab-allocate-summary',
});

export const simulationAllocatePolicyTabKey = persistAtom<AllocateTabId>({
  key: getKey('allocate-policy-tab-key'),
  default: 'tab-allocate-policy-allocation',
});

export const simulationOptimisationPolicyTabKey =
  persistAtom<OptimisationPolicyTabId>({
    key: getKey('optimisation-policy-tab-key'),
    default: 'tab-optimisation-settings',
  });

export const simulationAnalyseTabKey = atom<AnalyseTabId>({
  key: getKey('analyse-tab-key'),
  default: 'tab-analyze-summary',
});

export const simulationPolicyTabKey = atom<PoliciesTabId>({
  key: getKey('policy-tab-key'),
  default: 'tab-policy-picking',
});

export const simulationPickingPolicyTabKey = atom<PoliciesTabId>({
  key: getKey('policy-picking-tab-key'),
  default: 'tab-policy-picking',
});

export const simulationOptimiseTabKey = atom<OptimisationPolicyTabId>({
  key: getKey('optimise-tab-key'),
  default: 'tab-optimisation-status',
});

export enum ViewMode {
  GUIDED = 'guided',
  PRO = 'pro',
}

export const viewMenuModesAtom = atom<ViewMode[]>({
  key: getKey('view-menu-modes-atom'),
  default: [ViewMode.GUIDED, ViewMode.PRO],
  effects: [
    recoilPersist({
      key: getKey('view-menu-modes'),
      storage: localStorage,
    }).persistAtom,
  ],
});

export const selectedViewModeAtom = persistAtom<ViewMode>({
  key: getKey('selected-view-mode-atom'),
  default: ViewMode.GUIDED,
});
