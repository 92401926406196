import {
  AssignmentOccupancyDataFragment,
  AssignmentOccupancyFragment,
  AssignmentOccupancyLocationStatusFragment,
  AssignmentOccupancyMetaFragment,
  AssignmentOccupancySummaryGroupBy,
  AssignmentOccupancySummaryGroupFragment,
  BatchJobStatus,
  LoadAssignmentOccupancyByGroupDocument,
  LoadAssignmentOccupancyByGroupQuery,
  LoadAssignmentOccupancyByGroupQueryVariables,
  LoadAssignmentOccupancyDocument,
  LoadAssignmentOccupancyQuery,
  LoadAssignmentOccupancyQueryVariables,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { secureClient } from '../../../GraphQLClient';
import { AsyncLoadStatus, DataTableState } from '../../../common/types';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLevel,
  viewerSelectedPlaneId,
} from '../../../layout/viewer/store/viewer.state';
import { AssignmentOccupancyTableColumn } from '../../../occupancy/AssignmentOccupancyTable';
import {
  simulationCurrentId,
  simulationEffectiveAssignmentId,
} from '../simulation.state';
import {
  simulationComplianceTabKey,
  simulationWizardSelectedStepId,
} from '../simulation.wizard.state';
import {
  categoryOccupancyWidths,
  loadOccupancyHeatmap,
} from './assignmentOccupancy.helper';
import { OccupancyCategoryFilter } from './assignmentOccupancy.types';

const getKey = (postfix: string) => `warebee-assignment-occupancy-${postfix}`;

export const assignmentOccupancyMeta = atom<AssignmentOccupancyMetaFragment>({
  key: getKey('document-atom'),
  default: null,
});

export const assignmentOccupancyStatus = selector<BatchJobStatus>({
  key: getKey('document-status'),
  get: ({ get }) => get(assignmentOccupancyMeta)?.status,
  set: ({ get, set }, value) => {
    set(assignmentOccupancyMeta, {
      ...get(assignmentOccupancyMeta),
      status: value,
    });
  },
});

export const assignmentOccupancyMetaLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('summary-load-status'),
  default: AsyncLoadStatus.Ok,
});

export const assignmentOccupancyCategoryFilter = atom<
  Partial<OccupancyCategoryFilter>
>({
  key: getKey('filtered-categories'),
  default: Object.create(null),
});

export const assignmentOccupancyLevelLocation = selector<
  Record<string, AssignmentOccupancyLocationStatusFragment>
>({
  key: getKey('locations-size-by-level'),
  get: async ({ get }) => {
    const simulationId = get(simulationCurrentId);
    const assignmentId = get(simulationEffectiveAssignmentId);
    const status = get(assignmentOccupancyStatus);
    const areaId = get(viewerSelectedPlaneId);
    const level = get(viewerSelectedLevel);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);

    if (
      status !== BatchJobStatus.READY ||
      _.isNil(level) ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-volume'
    )
      return null;

    const locationMap = await loadOccupancyHeatmap({
      simulationId,
      assignmentId,
      areaId,
      level,
    });
    return locationMap;
  },
});

export const assignmentOccupancyBayLocation = selector<
  Record<string, AssignmentOccupancyLocationStatusFragment>
>({
  key: getKey('locations-size-by-bay'),
  get: async ({ get }) => {
    const simulationId = get(simulationCurrentId);
    const assignmentId = get(simulationEffectiveAssignmentId);
    const status = get(assignmentOccupancyStatus);
    const areaId = get(viewerSelectedPlaneId);
    const bayId = get(viewerSelectedBayIdAtom);
    const stepId = get(simulationWizardSelectedStepId);
    const tab = get(simulationComplianceTabKey);

    if (
      status !== BatchJobStatus.READY ||
      _.isNil(bayId) ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-volume'
    ) {
      return null;
    }
    const locationMap = await loadOccupancyHeatmap({
      simulationId,
      assignmentId,
      areaId,
      bayId,
    });
    return locationMap;
  },
});

export const assignmentOccupancyCategories =
  selector<AssignmentOccupancyFragment>({
    key: getKey('categories-summary'),
    get: async ({ get }) => {
      const simulationId = get(simulationCurrentId);
      const assignmentId = get(simulationEffectiveAssignmentId);
      const status = get(assignmentOccupancyStatus);
      const stepId = get(simulationWizardSelectedStepId);

      const tab = get(simulationComplianceTabKey);

      if (
        status !== BatchJobStatus.READY ||
        stepId !== 'compliance' ||
        tab !== 'tab-compliance-volume'
      ) {
        return null;
      }

      const response = await secureClient.query<
        LoadAssignmentOccupancyQuery,
        LoadAssignmentOccupancyQueryVariables
      >({
        query: LoadAssignmentOccupancyDocument,
        variables: {
          simulationId,
          assignmentId,
          categoryWidths: categoryOccupancyWidths,
        },
      });
      return response.data?.simulation.assignmentOccupancy;
    },
  });

export const assignmentOccupancyShowHeatmap = atom<boolean>({
  key: getKey('show-heatmap'),
  default: true,
});

export const assignmentOccupancyShowHeatmapLegend = atom<boolean>({
  key: getKey('show-heatmap-legend'),
  default: true,
});

export const assignmentOccupancyDataTableState = atom<
  DataTableState<AssignmentOccupancyTableColumn>
>({
  key: getKey('data-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      locationId: SortDirection.ASC,
    },
  },
});

export const assignmentOccupancyLocationsData =
  atom<AssignmentOccupancyDataFragment>({
    key: getKey('table-data'),
    default: null,
  });

export const assignmentOccupancyLocationsDataLoadStatus = atom<AsyncLoadStatus>(
  {
    key: getKey('table-data-load-status'),
    default: AsyncLoadStatus.None,
  },
);

export const assignmentOccupancyDrillDownSelected =
  atom<AssignmentOccupancySummaryGroupBy>({
    key: getKey('drill-down'),
    default: AssignmentOccupancySummaryGroupBy.STATUS,
  });

export const assignmentOccupancyGroups = selector<
  AssignmentOccupancySummaryGroupFragment[]
>({
  key: getKey('by-group'),
  get: async ({ get }) => {
    const errorMsg = 'Cannot load occupancy by group';
    const simulationId = get(simulationCurrentId);
    const assignmentId = get(simulationEffectiveAssignmentId);
    const status = get(assignmentOccupancyStatus);
    const drill = get(assignmentOccupancyDrillDownSelected);
    const stepId = get(simulationWizardSelectedStepId);

    const tab = get(simulationComplianceTabKey);

    if (
      status !== BatchJobStatus.READY ||
      stepId !== 'compliance' ||
      tab !== 'tab-compliance-volume'
    ) {
      return null;
    }

    try {
      const response = await secureClient.query<
        LoadAssignmentOccupancyByGroupQuery,
        LoadAssignmentOccupancyByGroupQueryVariables
      >({
        query: LoadAssignmentOccupancyByGroupDocument,
        variables: {
          simulationId,
          assignmentId,
          groupBy: [drill],
        },
      });

      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }
      return response.data?.simulation?.assignmentOccupancy?.summaryByGroup;
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});
