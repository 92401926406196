import {
  LoadAssignmentOccupancyMetaDocument,
  LoadAssignmentOccupancyMetaQuery,
  LoadAssignmentOccupancyMetaQueryVariables,
  RunCalculateSimulationAssignmentOccupancyDocument,
  RunCalculateSimulationAssignmentOccupancyMutation,
  RunCalculateSimulationAssignmentOccupancyMutationVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { AsyncLoadStatus } from '../../common/types';
import { secureClient } from '../../GraphQLClient';
import { errorAppender } from '../../store/error.state';
import {
  assignmentOccupancyMeta,
  assignmentOccupancyMetaLoadStatus,
} from '../store/occupancy/assignmentOccupancy.state';

export type LoadAssignmentOccupancyMetaParams = {
  simulationId: string;
  assignmentId: string;
};

function useLoadAssignmentOccupancyMeta() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load size compliance metadata`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ snapshot, set }) => async () => {
    set(assignmentOccupancyMetaLoadStatus, AsyncLoadStatus.Loading);
  });

  const callLoad = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadAssignmentOccupancyMetaParams) => {
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(assignmentOccupancyMetaLoadStatus, AsyncLoadStatus.Error);
          return;
        }

        const query = secureClient.watchQuery<
          LoadAssignmentOccupancyMetaQuery,
          LoadAssignmentOccupancyMetaQueryVariables
        >({
          query: LoadAssignmentOccupancyMetaDocument,
          variables: {
            simulationId: params.simulationId,
            assignmentId: params.assignmentId,
          },
        });

        const queryObservable = query.subscribe(
          async result => {
            const { data, errors } = result;
            if (errors) {
              console.error(errors);
              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let occupancyMeta = data?.simulation?.assignmentOccupancy;

            if (_.isNil(occupancyMeta)) {
              try {
                const response = await secureClient.mutate<
                  RunCalculateSimulationAssignmentOccupancyMutation,
                  RunCalculateSimulationAssignmentOccupancyMutationVariables
                >({
                  mutation: RunCalculateSimulationAssignmentOccupancyDocument,
                  variables: {
                    simulationId: params.simulationId,
                    assignmentId: params.assignmentId,
                  },
                });
                occupancyMeta =
                  response.data?.calculateSimulationAssignmentOccupancy;
              } catch (ex) {
                console.error(errorTitle, ex);
                handleError(ex.message || ex, ex.stack || null);
              }
            }

            set(assignmentOccupancyMeta, occupancyMeta);
            set(assignmentOccupancyMetaLoadStatus, AsyncLoadStatus.Ok);
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );

        setObservable(queryObservable);
      },
  );

  async function call(params: LoadAssignmentOccupancyMetaParams) {
    await initLoading();
    await callLoad(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}
export default useLoadAssignmentOccupancyMeta;
